import React from "react";

// Css
import "./styles.css";

export default function index({ close }) {
	const PROJECT_URL = "https://github.com/Ashwin-Codes/UpDrop.git";
	return (
		<div className="demons-backdrop">
			<div className="demons-modal">
				<div
					className="demons-close"
					onClick={() => {
						close(false);
					}}
				>
					✗
				</div>
				<div className="demons-intro-container">
					<h1 className="demons-intro">
						Hi, I am <span className="demons-name">Ashwin</span>.
					</h1>
					<h1 className="demons-subintro">This is demonstration of my project UpDrop.</h1>
				</div>
				<div className="demons-details-container">
					<h1 className="demons-details">
						Due to this being a demonstration, i have limited the file upload size to{" "}
						<u>10MB.</u>
					</h1>
					<h1 className="demons-details-2">
						Files you upload will automatically be <u>deleted</u> after some time.
					</h1>
				</div>
				<a className="demons-github-button" href={PROJECT_URL}>
					Projects's Github
				</a>
			</div>
		</div>
	);
}
