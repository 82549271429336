import { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// Pages
import Dashboard from "./pages/dashboard/";
import Configure from "./pages/configure/";

// Hooks
import useTheme from "./hooks/useTheme";

// Demonstration Component
import Demonstration from "./components/demonstration-modal";

function App() {
	const [showDemostrationModal, setShowDemostrationModal] = useState(true);

	// Theme Handler
	const { getCurrentTheme, setTheme } = useTheme();
	const currentTheme = getCurrentTheme();
	setTheme(currentTheme);

	return (
		<BrowserRouter>
			{showDemostrationModal && <Demonstration close={setShowDemostrationModal} />}
			<div className="App">
				<Routes>
					<Route path="*" element={<Navigate replace to={"/dashboard"} exact />} />
					<Route path="/" element={<Navigate replace to={"/dashboard"} exact />} exact />
					<Route path="/dashboard/*" element={<Dashboard />} exact />
					<Route path="/configure" element={<Configure />} exact />
				</Routes>
			</div>
		</BrowserRouter>
	);
}

export default App;
